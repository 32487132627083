exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./AppSILR.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./AppSILI.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./AppSILB.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./AppSILBI.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./icomoon.ttf"));

// Module
exports.push([module.id, "@font-face {\n    font-family: 'Apparatus SIL';\n    src: url(" + ___CSS_LOADER_URL___0___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Apparatus SIL';\n\tfont-style: italic;\n    src: url(" + ___CSS_LOADER_URL___1___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Apparatus SIL';\n\tfont-weight: bold;\n    src: url(" + ___CSS_LOADER_URL___2___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Apparatus SIL';\n\tfont-weight: bold;\n\tfont-style: italic;\n    src: url(" + ___CSS_LOADER_URL___3___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Icomoon';\n    src: url(" + ___CSS_LOADER_URL___4___ + ") format('truetype');\n}\n", ""]);

